import { useEffect, useState } from "react"
import { getInstagramFeed } from "../helpers/instagram-feed-helper";

export const useInstagramFeeds = (pageAfter) => {
    const [loading, setLoading] = useState(true)
    const [images, setImages] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [afterCursor, setAfterCursor] = useState("")
    
    useEffect(() => {
        
        (async () => {
            setLoading(true)
            const result = await getInstagramFeed(pageAfter);
            console.log(result);
            const filteredRes = result.data.filter(x => x.media_type === "IMAGE" || x.media_type === "CAROUSEL_ALBUM");
            setImages(prev => {
                return [...prev, ...filteredRes]
            })
            setHasMore(result.paging.next && result.paging.cursors.before != result.paging.cursors.after)
            setAfterCursor(result.paging.cursors.after)            
            setLoading(false)
        })()

    }, [pageAfter])


    return { loading, images, hasMore, afterCursor }
}