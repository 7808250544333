import { httpClient } from "./http-client-helper";

export const getInstagramFeed = async (after) => {
    try {        
        const limit = 9;
        const result = await httpClient().get(`/instagram?limit=${limit}&after=${after}`);        
        return result.data;
    } catch (error) {
        console.error("Unable to get instagram feed.")
    }
}

